import React from 'react';
import './App.css';
import { ToDo } from './components/ToDo';

function App() {
  const todos = [
    { id: 1, title: 'Aprender TypeScript', description: 'Estudiar los conceptos básicos', completed: false },
    { id: 2, title: 'Practicar React', description: 'Crear una aplicación con React y TypeScript', completed: false },
    { id: 3, title: 'Probar el código', description: 'Ejecutar la aplicación y asegurarse de que todo funcione', completed: true },
  ];

  return (
    <div className="App">
      <header className="App-header">
        <ToDo/>
      </header>
    </div>
  );
}

export default App;

