import React, { useState, useEffect } from 'react';

// Definimos una interfaz para describir la estructura de cada tarea
interface TodoItem {
    id: number;
    title: string;
    description: string;
    completed: boolean;
}

// Componente funcional ToDo
export const ToDo: React.FC = () => {
    // Estado para almacenar la lista de tareas. Inicializa con las tareas guardadas en Local Storage si existen.
    const [todos, setTodos] = useState<TodoItem[]>(() => {
        // Recuperamos las tareas guardadas desde Local Storage
        const savedTodos = localStorage.getItem('todos');
        // Si hay tareas guardadas, las parseamos a JSON, si no, usamos un array vacío
        return savedTodos ? JSON.parse(savedTodos) : [];
    });

    // Efecto para guardar las tareas en Local Storage cada vez que cambia el estado `todos`
    useEffect(() => {
        // Convertimos las tareas a JSON y las guardamos en Local Storage
        localStorage.setItem('todos', JSON.stringify(todos));
    }, [todos]); // Dependencia: se ejecuta cada vez que cambia el estado `todos`

    // Función para agregar una nueva tarea a la lista
    const addTodo = (title: string, description: string) => {
        // Creamos un nuevo objeto de tarea
        const newTodo: TodoItem = {
            id: todos.length + 1, // Generamos un ID único para la nueva tarea. Se asume que el ID se genera como longitud del array + 1
            title,
            description,
            completed: false, // Las tareas nuevas comienzan como no completadas
        };
        // Actualizamos el estado con la nueva tarea agregada a la lista
        setTodos([...todos, newTodo]);
    };

    // Función para manejar el cambio en el estado del checkbox
    const handleCheckboxChange = (id: number) => {
        // Actualizamos el estado de la tarea con el ID correspondiente
        setTodos(todos.map(todo =>
            todo.id === id ? { ...todo, completed: !todo.completed } : todo
        ));
    };

    // Función para eliminar las tareas completadas
    const handleDeleteCompleted = () => {
        // Filtramos las tareas para mantener solo las que no están completadas
        setTodos(todos.filter(todo => !todo.completed));
    };

    // Manejador del evento de envío del formulario
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Previene el comportamiento por defecto del formulario, que es recargar la página
        // Creamos un objeto FormData con los datos del formulario
        const formData = new FormData(event.currentTarget);
        // Obtenemos los valores del título y la descripción del formulario
        const title = formData.get('title') as string;
        const description = formData.get('description') as string;
        // Verificamos que tanto el título como la descripción no estén vacíos
        if (title && description) {
            // Llamamos a la función para agregar la nueva tarea
            addTodo(title, description);
            // Limpiamos el formulario después de agregar la tarea
            event.currentTarget.reset();
        }
    };

    return (
        <div>
            <h2>Agregar Tarea</h2>
            {/* Formulario para agregar una nueva tarea */}
            <form onSubmit={handleSubmit}>
                {/* Campo de entrada para el título de la tarea */}
                <input type="text" name="title" placeholder="Título" required />
                {/* Campo de entrada para la descripción de la tarea */}
                <input type="text" name="description" placeholder="Descripción" required />
                {/* Botón para enviar el formulario */}
                <button type="submit">Agregar Tarea</button>
            </form>

            {/* Botón para eliminar las tareas completadas */}
            <button onClick={handleDeleteCompleted}>Eliminar Tareas Completadas</button>

            {/* Lista de tareas */}
            <ul>
                {/* Iteramos sobre el array de tareas y renderizamos cada una */}
                {todos.map(item => (
                    <li className="list" key={item.id}>
                        {/* Checkbox para marcar la tarea como completada o pendiente */}
                        <input
                            type="checkbox"
                            checked={item.completed}
                            onChange={() => handleCheckboxChange(item.id)}
                        />
                        {/* Título de la tarea */}
                        <h3>{item.title}</h3>
                        {/* Descripción de la tarea */}
                        <p>{item.description}</p>
                        <br></br>
                        {/* Estado de la tarea (Completada o Pendiente) */}
                        <p>{item.completed ? 'Completada' : 'Pendiente'}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};
